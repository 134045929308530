import { render, staticRenderFns } from "./OntologyViewJoint.vue?vue&type=template&id=47df6060&scoped=true"
import script from "./OntologyViewJoint.vue?vue&type=script&lang=js"
export * from "./OntologyViewJoint.vue?vue&type=script&lang=js"
import style0 from "./OntologyViewJoint.vue?vue&type=style&index=0&id=47df6060&prod&scoped=true&lang=scss"
import style1 from "./OntologyViewJoint.vue?vue&type=style&index=1&id=47df6060&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47df6060",
  null
  
)

export default component.exports