import { ref, watch, toRef } from '@vue/composition-api';
export function useColourBy(context, props, store, graph, treeData, currentSet, buildNodeCss) {
    const nextSet = ref(null);
    const selectedColourBy = ref('');
    const canvasClass = ref('');
    const colourBySidebarVisible = ref(false);
    const isKeyVisible = ref(true);
    const triggerRefresh = toRef(props, 'refreshColourBy');
    const { emit } = context;
    watch(triggerRefresh, shouldRefresh => {
        applyCssChanges(currentSet.value.transformedData);
    }, { immediate: true });
    function changeColourBySet(keySet) {
        // Unset the trl date field - regardless of whether it is set or not
        store.dispatch('domainModel/selectTRLDate', null);
        nextSet.value = keySet;
        if (keySet.modal) {
            // wait til the colourByDataLoaded callback is fired before changing the colourBy canvasclass
            context.root.$bvModal.show(keySet.modal);
            return;
        }
        if (keySet.sidebarConfig) {
            colourBySidebarVisible.value = true;
            if (nextSet.value) {
                currentSet.value = nextSet.value;
            }
            return;
        }
        localStorage.setItem(`selectedColourBy${props.outerPrefix}`, keySet.name);
        // data is already on the node, change the canvasClass
        applyCssChanges(null);
    }
    async function colourByDataLoaded(loadedData) {
        nextSet.value.rawData = loadedData;
        if (nextSet.value.transformData) {
            nextSet.value.transformedData = await Promise.resolve(nextSet.value.transformData(loadedData));
        }
        else {
            nextSet.value.transformedData = loadedData;
        }
        if (nextSet.value.generateItems) {
            nextSet.value.items = nextSet.value.generateItems(nextSet.value.transformedData);
        }
        applyCssChanges(nextSet.value.transformedData);
    }
    function applyCssChanges(loadedData) {
        if (nextSet.value) {
            currentSet.value = nextSet.value;
        }
        setTreeCss(props.graph, props.treeData, loadedData, currentSet.value);
        emit('onChangeColourBy', `${props.outerPrefix}-${currentSet.value.name}`);
    }
    function getText(n, data) {
        if (currentSet.value.getDescription) {
            return currentSet.value.getDescription(n, data);
        }
        return n.description || n;
    }
    function clickColourKeyItem(keyName) {
        emit('item-clicked', keyName);
    }
    function setTreeCss(graph, tree, data, keySet) {
        // need to clear existing data, so we need to update all nodes
        tree?.nodes?.forEach(tn => {
            const mappedResult = keySet.resultMapper && data ? keySet.resultMapper(tn, data).toLocaleLowerCase() : '';
            let result = mappedResult || tn[keySet.nodeProperty] || '';
            const matchedItem = keySet.items.find(i => typeof i !== 'string' && i.name?.toLocaleLowerCase() === result.toLocaleLowerCase());
            if (matchedItem && typeof matchedItem !== 'string' && matchedItem.cssName) {
                result = matchedItem.cssName;
            }
            tn[keySet.nodeProperty] = result;
            const cell = graph.getCell(tn.id);
            if (cell) {
                const css = `${buildNodeCss(tn)} ${keySet.prefix}${result}`;
                cell.attr('body/class', css);
                cell.attr('shape/class', css);
            }
        });
    }
    function getNodeTestednessCss(testState) {
        return `bn-test-${testState.replace(' ', '-').toLocaleLowerCase()}`;
    }
    return {
        selectedColourBy,
        buildNodeCss,
        setTreeCss,
        getText,
        clickColourKeyItem,
        changeColourBySet,
        colourByDataLoaded,
        colourBySidebarVisible,
        isKeyVisible,
    };
}
